<template>
  <v-container class="d-print-block">
    <div class="mb-3">
      <h1><span v-html="$t('search_mhw_chart')" />{{ getAgisId }}</h1>

      <v-row class="row search spacer-md d-print-none">
        <v-col xs="12" sm="6" md="3">
          <v-form ref="searchform">
            <search-mhw ref="search-mhw" @search="search" />
          </v-form>
        </v-col>
      </v-row>
      <v-row class="d-print-none">
        <v-col>
          <v-btn :loading="loadingMilkQualityBenchmarks || loadingMilkQualitySamples" color="primary" @click="search" :disabled="!hasPostcodeCluster()"
            ><span v-html="$t('search_mhw_search')"
          /></v-btn>
          <v-btn color="secondary" @click="$refs['search-mhw'].reset()"><span v-html="$t('search_mhw_reset')" /></v-btn>
          <v-btn color="secondary" @click="togglePrintview()" :disabled="!hasPostcodeCluster()"
            ><span v-html="$t(printview ? 'search_mhw_fullscreen' : 'search_mhw_printview')"
          /></v-btn>
          <v-btn color="secondary" @click="printPage" v-if="printview" :disabled="!hasPostcodeCluster()"><span v-html="$t('search_mhw_print')" /></v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="showNoData">
      <span v-html="$t('mhw_no_data_available')"></span>
    </div>
    <div id="outherChartContainer" :style="chartContainerCSS" class="d-print-block" v-else>
      <div v-if="chartOptions" ref="myChartsContainer" id="myChartsContainer" class="myChartsContainer" width="100%"></div>
    </div>
    <v-row>
      <v-col> <div v-html="$t('mhw_postcodecluster_notes')"></div></v-col>
    </v-row>
    <v-row v-if="hasPostcodeCluster()">
      <v-col sm="12" lg="6" xl="4">
        <v-table>
          <tr>
            <td><span v-html="$t('mhw_postcodecluster_codes')"></span></td>
            <td>{{ SearchMilkQualityBenchmarkStore.postcodeCluster.codes }}</td>
          </tr>
          <tr>
            <td><span v-html="$t('mhw_postcodecluster_farmcount')"></span></td>
            <td>
              {{ SearchMilkQualityBenchmarkStore.postcodeCluster.farmCount }}
            </td>
          </tr>
        </v-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { useOwnManagedFarmsStore } from '@/store/OwnManagedFarmsStore'
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'

exportingInit(Highcharts)

import searchMhw from '@/components/searchCards/searchMilkQualityBenchmark.vue'
import { showError } from '@/services/axios'
import { apiURL } from '../../main'
import _ from 'lodash'
import { useSearchMilkQualityBenchmarkStore } from '@/store/SearchMilkQualityBenchmarkStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'milkqualitybenchmarks_chart',
  components: {
    searchMhw
  },
  data() {
    return {
      chartContainerCSS: '',
      printview: false,
      showNoData: false,
      messabweichung: 21.4 / 100, // 21.4 procent
      loadingMilkQualityBenchmarks: false,
      loadingMilkQualitySamples: false,
      milkQualitySamples: [],
      milkQualityBenchmarks: [],
      chart: {},
      chartOptions: {
        stickyTracking: true, // avoids the line a littlebit
        chart: {
          zoomType: 'xy',
          width: null,
          type: 'scatter'
        },
        title: {
          text: '' //this.$t('search_mhw_chart_title')
        },
        xAxis: [
          {
            id: 'bottom-datetime-axis',
            currentDateIndicator: {
              width: 2,
              dashStyle: 'DashDot',
              color: 'blue',
              label: {
                format: '%m.%Y'
              }
            },
            type: 'datetime',
            /* tickInterval: day, */
            tickInterval: 30 * 24 * 3600 * 1000,
            labels: {
              format: '{value:%m.%Y}'
            }
          }
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: '{value}',
              style: {
                //color: 'darkgrey'
              }
            },
            title: {
              text: this.$t('search_mhw_chart_xaxis'),
              style: {
                //color: 'darkgrey'
              }
            }
          }
        ],

        tooltip: {
          shared: true
        },
        plotOptions: {
          scatter: {
            marker: {
              color: 'black',
              symbol: 'circle',
              radius: 3
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} mg/dl</b> '
            }
          }
        },
        series: []
      }
    }
  },
  computed: {
    getFarms() {
      return useOwnManagedFarmsStore().farms
    },
    SearchMilkQualityBenchmarkStore() {
      return useSearchMilkQualityBenchmarkStore()
    },
    getAgisId() {
      if (!this.getFarms.length) return 0
      let test = this.getFarms.filter((f) => f.id == this.SearchMilkQualityBenchmarkStore.farmId)
      return test.length ? test[0].agisId : 0
    }
  },
  methods: {
    printWidth() {
      if (navigator.userAgent.includes('Firefox')) {
        return 550
      } else {
        return 745
      }
    },
    togglePrintview() {
      if (!this.$refs['searchform'].validate().valid) return
      this.printview = !this.printview
      //this.chartContainerCSS = this.printview ? 'width:640px !important;' : 'width:100%;'

      this.chartOptions.chart.width = this.printview ? this.printWidth() : null
      this.reloadChart()
    },
    printPage() {
      window.print()
    },
    queryParams() {
      return {
        postcodeClusterId: useSearchMilkQualityBenchmarkStore()?.postcodeCluster?.id,
        farmId: useSearchMilkQualityBenchmarkStore().farmId,
        from: useSearchMilkQualityBenchmarkStore().from,
        until: useSearchMilkQualityBenchmarkStore().until
      }
    },
    search() {
      if (!this.$refs['searchform'].validate().valid) return
      this.$router
        .push({
          path: this.$route.path,
          query: this.queryParams()
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      const mqb = await this.loadMilkQualityBenchmarks()
      const mqs = await this.loadMilkQualitySamples()
      if ((await mqb) && (await mqs)) {
        console.log('loaded', mqb, mqs)
        this.milkQualityBenchmarks = mqb
        this.milkQualitySamples = mqs
        // this.setChartOptionsTime(useSearchMilkQualityBenchmarkStore().from, useSearchMilkQualityBenchmarkStore().until)
        this.setChartOptionsBenchmark(this.milkQualityBenchmarks, useSearchMilkQualityBenchmarkStore().from, useSearchMilkQualityBenchmarkStore().until)
        this.setChartOptionsSamples(this.milkQualitySamples, useSearchMilkQualityBenchmarkStore().from, useSearchMilkQualityBenchmarkStore().until)
        this.reloadChart()
      }
    },
    setChartOptionsTime(from, until) {
      this.chartOptions.xAxis[0].min = new this.$moment(from).add(-1, 'M').valueOf()
      this.chartOptions.xAxis[0].max = new this.$moment(until).add(1, 'M').valueOf()
    },
    setChartOptionsBenchmark(milkQualityBenchmarks, from, until) {
      let mqb = [...milkQualityBenchmarks]
      let averageLine = []
      let averageArea = []

      const start = this.$moment(from)
      const end = this.$moment(until).add(1, 'month')
      let current = start
      while (current.format('MM YYYY') !== end.format('MM YYYY')) {
        let elementIndx = mqb.findIndex((element) => element.period === current.format('YYYY-MM-DD'))
        if (elementIndx >= 0) {
          averageLine.push({
            x: current.toDate().getTime(),
            y: mqb[elementIndx].average
          })
          averageArea.push({
            x: current.toDate().getTime(),
            low: mqb[elementIndx].average - mqb[elementIndx].standardDeviation,
            high: mqb[elementIndx].average + mqb[elementIndx].standardDeviation
          })
          mqb.splice(elementIndx, 1)
        } else {
          averageLine.push(null)
          averageArea.push(null)
        }
        current = current.add(1, 'month')
      }

      this.chartOptions.series[0] = {
        name: this.$t('search_mhw_chart_yaxis_referenz'),
        type: 'line',
        xAxis: 0,
        marker: { enabled: false },
        color: '#A1DB00',
        data: averageLine,
        tooltip: {
          pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} mg/dl</b> '
        }
      }

      this.chartOptions.series[1] = {
        name: this.$t('search_mhw_chart_yaxis_standarddeviation'),
        type: 'arearange',
        lineWidth: 0,
        xAxis: 0,
        linkedTo: ':previous',
        color: '#A1DB00',
        fillOpacity: 0.15,
        zIndex: 0,
        marker: { enabled: false },
        data: averageArea,
        tooltip: {
          pointFormat: '<br/>'
        }
      }
    },

    setChartOptionsSamples(milkQualitySamples, from, until) {
      let points = milkQualitySamples.map((sample) => {
        return [new Date(sample.period).getTime(), sample.urea]
      })

      this.chartOptions.series[2] = {
        xAxis: 0,
        name: this.$t('search_mhw_chart_yaxis_samplex'),
        //color: 'rgba(223, 83, 83, .5)',
        color: '#000000',
        data: points,
        tooltip: {
          pointFormat: '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.1f} mg/dl</b> '
        }
      }

      let errorbar = milkQualitySamples.map((sample) => {
        return {
          x: new Date(sample.period).getTime(),
          low: sample.urea * (1 + this.messabweichung),
          high: sample.urea * (1 - this.messabweichung),
          name: 'Point2',
          color: '#444444'
        }
      })
      this.chartOptions.series[3] = {
        //xAxis: 0,
        type: 'errorbar',
        color: 'grey',
        name: 'errorbar',
        data: errorbar,
        whiskerWidth: 1,
        whiskerLength: 10,
        tooltip: {
          pointFormat: '<br />'
        }
      }
    },
    async loadMilkQualityBenchmarks() {
      this.loadingMilkQualityBenchmarks = true
      const response = await this.axios.get(apiURL + '/milkQualityBenchmarks/find', {
        params: _.omit(this.queryParams(), 'farmId')
      })
      this.loadingMilkQualityBenchmarks = false
      return response.data
    },
    async loadMilkQualitySamples() {
      this.loadingMilkQualitySamples = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualityBenchmarks/findSamples', {
          params: { ...this.queryParams(), page: 0, size: 1000 }
        })
        this.showNoData = (await response.data.content.length) === 0
        return await response.data.content
      } catch (error) {
        showError(error)
        return []
      } finally {
        this.loadingMilkQualitySamples = false
      }
    },
    reloadChart() {
      this.chartOptions.xAxis[0].min = new Date(useSearchMilkQualityBenchmarkStore().from).getTime()
      this.chartOptions.xAxis[0].max = new Date(useSearchMilkQualityBenchmarkStore().until).getTime()
      if (!this.showNoData && this.milkQualityBenchmarks.length > 0 && this.milkQualitySamples.length > 0) {
        this.chart = Highcharts.chart(this.$refs.myChartsContainer, this.chartOptions)
      }
    },
    hasPostcodeCluster() {
      return this.SearchMilkQualityBenchmarkStore.postcodeCluster && this.SearchMilkQualityBenchmarkStore.postcodeCluster.codes
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    },
    'SearchMilkQualityBenchmarkStore.postcodeCluster.codes': {
      handler(newValue) {
        if (this.milkQualityBenchmarks.length === 0) {
          //console.log('SearchMilkQualityBenchmarkStore.postcodeCluster watcher')
          //this.load()
        }
        console.log('SearchMilkQualityBenchmarkStore.postcodeCluster watcher')
        this.showNoData = false
        this.$refs.myChartsContainer.innerHTML = ''
      },
      deep: true
    }
  },
  mounted() {
    useOwnManagedFarmsStore().fetchOwnManagedFarms()
    if (this.SearchMilkQualityBenchmarkStore.farmId && this.SearchMilkQualityBenchmarkStore.postcodeCluster.id) {
      console.log('mounted')
      this.load()
    } else {
    }
  }
})
</script>
